//@ts-nocheck
import React from 'react'

function ButtonWithIcon({ label, link, icon, color }) {

    if (color === undefined) {
        color = "blue"
    } 

    const buttonColourMap = {
        "red": "#D26568",
        "blue": "#1e293b"
    }
    const buttonTintMap = {
        "red": "#af3337",
        "blue": "#0f172a"
    }

    var backgroundStyle = {
        backgroundColor: buttonColourMap[color]
    }
    
    return (
        <>
            <a href={`${link}`} target='_blank' >
                <button type="button" style={backgroundStyle} class="text-white hover:bg-slate-900 focus:ring-2 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-2 py-1 text-center inline-flex items-center">
                    {label}
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5 ml-1 mb-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                </button>
            </a>
        </>

    )
}

export default ButtonWithIcon