//@ts-nocheck
import React from 'react'
import Divider from '../../Divider/Divider'
import Magnifier from "react-magnifier";
import ButtonWithIcon from '../../Buttons/ButtonWithIcon';
import Tags from '../../Tags/Tags';

function AutumnWatch({ data, isMobile }) {
    return (
        <>
            <video autoPlay loop muted webkit-playsinline={true} playsInline>
                <source src={data.images[0]} type="video/mp4" />
            </video>
            <div className={'sm:w-1/4 md:w-3/4'}>
                <Divider width={'full'} />
                <div className={isMobile ? "px-10 py-10" : "pb-10"}>
                    <div className='text-left'>
                        <h1 class="mb-2 text-3xl font-extrabold leading-none tracking-tight text-slate-800 md:text-4xl lg:text-5xl">
                            {data.title}</h1>
                        <div className='mb-2'>
                            <Tags tags={data.tags} />
                        </div>
                        <p class="mb-6 text-sm font-normal text-slate-800">
                            {data.description}</p>
                        <div className='flex gap-2'>
                            <ButtonWithIcon label={"See it live"} link={data.external[0]} />
                            <ButtonWithIcon label={"See award nomination"} link={data.external[1]} color={"red"} />
                        </div>
                    </div>
                </div>

                <Magnifier src={data.images[2]} zoomImgSrc={data.zoomed[1]} mgShape="square"/>
                <Divider width={'full'} />
                <Magnifier src={data.images[3]} zoomImgSrc={data.zoomed[2]} className='h-full' mgShape="square"/>
                <Divider width={'full'} />
                <div className='flex w-full justify-center'>
                    <Magnifier src={data.images[1]} zoomImgSrc={data.zoomed[0]} className='!w-60 h-auto' mgShape="square"/>
                </div>
            </div>
        </>
    )
}

export default AutumnWatch