// @ts-nocheck
import './ImageGallery.css'
import Square from "./Square";

const images = require('../../assets/images.json');
function ImageGallery({selectedCategory, modal, setModal, closeModal, isMobile}) {
    return (
        <div className='grid grid-cols-2 grid-rows-auto gap-0 my-0 mx-0 h-auto sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 w-full'>
            {images.filter((image) => {
                return image.tags.includes(selectedCategory)
            }).map((image: any, index: any) => {
                return <Square image={image} index={index} setModal={setModal} />
            })}
        </div>
    );
}

export default ImageGallery;