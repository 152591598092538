// @ts-nocheck
import React from "react";

function Divider({ width }) {
    return (
        <div className="flex justify-center">
            <hr className={`h-px w-${width} my-8 bg-gray-200 border-0`}/>
        </div>
    );
}

export default Divider