//@ts-nocheck
import React from 'react'

function BlurredBackground({closeModal}) {
  return (
    <div className='animate-background_show right-0 bg-slate-400 opacity-70 fixed w-4/5 h-full z-9' onClick={() => {
      closeModal()
    }}></div>
  )
}

export default BlurredBackground