// @ts-nocheck
import React from 'react'
import { useState } from "react";

function Square({ image, index, setModal }) {

    const [effect, showAnimation] = useState({
        id: null,
        show: false,
      });

    const { link, square_animation, title, external, id, tags, description, description_2, subtext, images, zoomed } = image;

    const renderSquare = () => {
        console.log(square_animation)
        if (square_animation === undefined) {
            return (
                <img
                    className='tile-img object-cover w-full h-full overflow-hidden'
                    key={index}
                    src={link}
                    alt={`${index}`}
                    draggable={false}
                    onClick={() => {
                        showAnimation({
                            id: id,
                            show: true
                        })
                        setTimeout(() => {
                            setModal({
                                show: true,
                                data: {
                                    key: index,
                                    id: id,
                                    link: link,
                                    title: title,
                                    external: external,
                                    tags: tags,
                                    description: description,
                                    description_2: description_2,
                                    subtext: subtext,
                                    images: images,
                                    zoomed: zoomed
                                }
                            });
                            Array.from(document.querySelectorAll('body')).forEach(
                                (el) => el.classList.add('modal-open')
                            );
                            showAnimation({
                                id: null,
                                show: false
                            })
                        }, 250)

                    }}
                />
            )
        }
        return (
            <video
                className='tile-img object-cover w-full h-full overflow-hidden'
                key={index}
                poster={link}
                alt={`${index}`}
                draggable={false}
                onClick={() => {
                    showAnimation({
                        id: id,
                        show: true
                    })
                    setTimeout(() => {
                        setModal({
                            show: true,
                            data: {
                                key: index,
                                id: id,
                                link: link,
                                title: title,
                                external: external,
                                tags: tags,
                                description: description,
                                description_2: description_2,
                                subtext: subtext,
                                images: images,
                                zoomed: zoomed
                            }
                        });
                        Array.from(document.querySelectorAll('body')).forEach(
                            (el) => el.classList.add('modal-open')
                        );
                        showAnimation({
                            id: null,
                            show: false
                        })
                    }, 250)

                }}
                onMouseOver={event => event.target.play()}
                onMouseOut={ event => {
                    // event.target.pause()
                    event.target.load()
                }
                }
                src={`${square_animation}`}
                muted
                webkit-playsinline={true} playsInline
                controls={false}>
            </video>
        )
    }

    return (
        <div className={`${id === effect.id && effect.show && "animate-bounce_image "} cursor-pointer w-full h-full overflow-hidden`}>
            {
                renderSquare()
            }
        </div>
    )
}

export default Square