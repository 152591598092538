import React, { useEffect, useState } from 'react';

import ImageGallery from './components/ImageGallery/ImageGallery'
import Sidebar from './components/Sidebar/Sidebar'
import { CATEGORIES } from './constants/categories';
import MobileNavBar from './components/MobileNavBar/MobileNavBar';
import Modal from './components/Modal/Modal';
import BlurredBackground from './components/BlurredBackground/BlurredBackground';
import Copyright from './components/Copyright/Copyright';
function App() {
  const [category, setCategory] = useState(CATEGORIES.ALL);

  const [isDesktop, setDesktop] = useState(window.innerWidth > 640);
  const [isMobile, setMobile] = useState(window.innerWidth < 640);
  const updateMedia = () => {
    setDesktop(window.innerWidth > 640);
    setMobile(window.innerWidth < 640);
  };

  const [modal, setModal] = useState({
    show: false,
    data: {
        key: null,
        link: null,
        title: null,
        tags: null,
        description: null,
        description_2: null,
        subtext: null
    }
  });
  const handleClose = () => {
      setModal({
          show: false,
          data: {
              key: null,
              link: null,
              title: null,
              tags: null,
              description: null,
              description_2: null,
              subtext: null
          }
      });
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="App font-helvetica flex flex-col sm:flex-row overflow-auto w-screen h-screen">
        {isDesktop && <Sidebar selectedCategory={category} setCategoryHandler={setCategory} setModal={setModal} closeModal={handleClose} />}
        {isMobile && <MobileNavBar selectedCategory={category} setCategoryHandler={setCategory} setModal={setModal}/>}
        <div className='flex flex-col sm:w-4/5'>
          <ImageGallery modal={modal} setModal={setModal} closeModal={handleClose} selectedCategory={category} isMobile={isMobile}/>
          <Copyright/>
        </div>
        {modal.show && <Modal closeModal={handleClose} data={modal.data} isMobile={isMobile} />}
        {modal.show && <BlurredBackground closeModal={handleClose} />}
    </div>
  );
}

export default App;
